
  import { mapState } from 'vuex'
  
  export default {
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      ...mapState(['channel']),
    },
  }
